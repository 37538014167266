var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "modal-class": "product-modal",
        size: "lg",
        centered: "",
        title: _vm.modalTitle,
        "no-close-on-backdrop": "",
        scrollable: "",
        "no-enforce-focus": "",
        "body-class": "no-scrollable",
      },
      on: {
        hidden: function ($event) {
          return _vm.close()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c("megau-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDeleteButton,
                    expression: "showDeleteButton",
                  },
                ],
                attrs: {
                  classprop: "btn btn-danger",
                  icon: "times",
                  title: _vm.$t("deletepackage"),
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.openModal()
                  },
                },
              }),
              _c("megau-button", {
                attrs: {
                  classprop: "btn btn-success",
                  icon: "check",
                  title: _vm.buttonTitle,
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.update()
                  },
                },
              }),
              _c("megau-button", {
                attrs: {
                  classprop: "btn btn-primary",
                  icon: "undo",
                  title: _vm.$t("closewithoutsav"),
                },
                on: {
                  handleClick: function ($event) {
                    return _vm.close()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      this.showFormAdd
        ? _c("vue-form-generator", {
            ref: "detailFormAdd",
            attrs: {
              schema: this.schemaAdd,
              model: this.modelAdd,
              options: this.formOptions,
            },
          })
        : _vm._e(),
      this.showFormEdit
        ? _c("vue-form-generator", {
            ref: "detailFormEdit",
            attrs: {
              schema: this.schemaEdit,
              model: this.modelEdit,
              options: this.formOptions,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }