<template>
	<b-modal
		modal-class="product-modal"
		size="lg"
		v-model="showModal"
		centered
		:title="modalTitle"
		@hidden="close()"
		no-close-on-backdrop
		scrollable
		no-enforce-focus
		body-class="no-scrollable"
	>
		<vue-form-generator
			v-if="this.showFormAdd"
			:schema="this.schemaAdd"
			:model="this.modelAdd"
			:options="this.formOptions"
			ref="detailFormAdd"
		></vue-form-generator>
		<vue-form-generator
			v-if="this.showFormEdit"
			:schema="this.schemaEdit"
			:model="this.modelEdit"
			:options="this.formOptions"
			ref="detailFormEdit"
		></vue-form-generator>

		<template v-slot:modal-footer>
			<megau-button
				v-show="showDeleteButton"
				classprop="btn btn-danger"
				icon="times"
				:title="$t('deletepackage')"
				@handleClick="openModal()"
			></megau-button>
			<megau-button classprop="btn btn-success" icon="check" :title="buttonTitle" @handleClick="update()"></megau-button>
			<megau-button
				classprop="btn btn-primary"
				icon="undo"
				:title="$t('closewithoutsav')"
				@handleClick="close()"
			></megau-button>
		</template>
	</b-modal>
</template>
<script>
import vue from 'vue';

import serviceEnums from '../../services/service/enums.service';
import apiCommon from '../../services/api/common.api';

import serviceCommon from '../../services/service/common.service';
import serviceDictionary from '../../services/service/dictionary.service';
import loading from '../../mixins/loading.mixin';

import { detailModel } from './product.model';
import service from './product.service';
import imports from './product.imports';
imports.importDetailComponents(vue);

export default {
	mixins: [loading],
	props: {
		uProductId: { type: Number },
		userId: { type: Number },
		objectId: { type: Number },
		isVisible: { type: Boolean, default: false },
		objectCountryId: { type: Number },
		invoiceId: { type: Number },
	},
	data() {
		return detailModel(this.$i18n);
	},
	computed: {
		productIdFormAddWatch() {
			return this.modelAdd.ProductId;
		},
		productIdFormEditWatch() {
			return this.modelEdit.ProductId;
		},
		activationFormAddWatch() {
			return this.modelAdd.Activation;
		},
	},
	mounted() {
		this.modelAdd.ObjectId = this.objectId;
		this.modelEdit.ObjectId = this.objectId;
		this.modelAdd.InvoiceId = this.invoiceId;
		this.modelEdit.InvoiceId = this.invoiceId;
	},

	methods: {
		loadPaymentFieldClass(payment, invoice) {
			var path = this.schemaEdit.groups[2].fields[1];
			if (!payment && invoice) {
				path.styleClasses = '';
			} else {
				path.styleClasses = 'productSettedUp';
			}
		},

		async fetchData() {
			await this.getProductSelect();
			await this.getProductData();
		},
		async getProductSelect() {
			let productList = await serviceDictionary.getProductListFormGenerator(this.objectCountryId);
			this.schemaAdd.groups[0].fields[0].values = productList;
			this.schemaEdit.groups[0].fields[0].values = productList;
		},
		async getProductData() {
			if (this.uProductId > 0) {
				let resp = await apiCommon.getEntity(this.uProductId, this.controllerName);
				this.fillEditModel(resp);
			} else {
				this.fillAddModel();
			}
		},
		close() {
			this.getNewProduct = false;
			this.clearAddModel();
			this.$emit('onClose');
		},
		clearAddModel() {
			this.modelAdd.ProductId = 0;
			this.modelAdd.UproductPrice = null;
			this.modelAdd.Activation = null;
		},
		openModal() {
			serviceCommon.openDeleteModal(this.modelEdit.UproductId, this.$modal, this.deleteThisItem);
		},
		deleteThisItem(id) {
			serviceCommon.deleteDetailItem(id, this.controllerName, this.closeModelAndUpdate);
		},
		closeModelAndUpdate() {
			this.$emit('onUpdate');
			this.showModal = false;
		},
		update() {
			var validateResult = this.showFormEdit ? this.$refs.detailFormEdit.validate() : this.$refs.detailFormAdd.validate();
			let _this = this;
			validateResult.then((value) => {
				if (value.length == 0) {
					_this.startLoading(false);
					if (_this.uProductId > 0) {
						apiCommon.putEntity(_this.modelEdit, _this.controllerName).then(() => {
							_this.stopLoading();
							_this.showModal = false;
							_this.$emit('onUpdate');
						});
					} else {
						apiCommon.postEntity(_this.modelAdd, _this.controllerName).then(() => {
							_this.stopLoading();
							_this.showModal = false;
							_this.$emit('onUpdate');
						});
					}
				}
			});
		},
		fillEditModel(resp) {
			let invoiceId = this.modelEdit.InvoiceId;
			this.modelEdit = resp.data;
			this.modelEdit.InvoiceId = invoiceId;
			this.showFormEdit = true;
			this.showFormAdd = false;
			this.showDeleteButton = this.modelEdit.InvoiceNumber ? false : true;
			this.modalTitle = this.$i18n.t('editpackage2426');
			this.buttonTitle = this.modelEdit.InvoiceNumber ? this.$i18n.t('savechangesande') : this.$i18n.t('savechangesandn');

			this.modelEdit.InvoiceLink = {
				iNumber: resp.data.InvoiceNumber,
				iCode: resp.data.InvoiceCode,
				iType: resp.data.InvoiceType,
			};

			this.loadPaymentFieldClass(this.modelEdit.UproductDatePayment, this.modelEdit.InvoiceNumber);
		},
		fillAddModel() {
			this.showFormAdd = true;
			this.showFormEdit = false;
			this.showDeleteButton = false;
			this.modalTitle = this.$i18n.t('addanewpackage2439');
			this.modelAdd.UproductDateTaxable = null;
		},
	},
	watch: {
		activationFormAddWatch: function() {
			if (this.modelAdd.Activation) {
				var field = this.schemaAdd.groups[2].fields[2];
				this.buttonTitle = field.dataval[this.modelAdd.Activation - 1].buttonValue;
			}
		},

		isVisible: function() {
			if (this.isVisible) {
				this.fetchData();
			}
			this.showModal = this.isVisible;
		},
		userId: function() {
			if (this.userId) {
				this.modelEdit.GuserId = this.userId;
			}
		},
		productIdFormAddWatch: function() {
			if (this.modelAdd.ProductId === 0) {
				return;
			}

			if (this.modelAdd.InvoiceId) {
				service.getProductForAddFormByInvoice(this.modelAdd.ProductId, this.modelAdd.InvoiceId).then((product) => {
					this.modelAdd = product.data;
				});
			} else {
				service.getProductForAddForm(this.modelAdd.ProductId, this.modelAdd.ObjectId).then((product) => {
					this.modelAdd = product.data;
				});
			}
		},
		productIdFormEditWatch: function() {
			if (this.getNewProduct) {
				service.getProductForEditForm(this.modelEdit.UproductId, this.modelEdit.ProductId).then((product) => {
					let model = this.modelEdit;
					model.UproductDateTaxable = product.data.UproductDateTaxable;
					model.UproductPrice = product.data.UproductPrice;
					model.UproductDateEnd = product.data.UproductDateEnd;
					model.ProductMonths = product.data.ProductMonths;

					let merged = { ...this.modelEdit, ...model };

					this.modelEdit = merged;
				});
			}

			this.getNewProduct = true;
		},
	},
};
</script>

<style lang="scss">
.field-invoice-link-wrapper {
	.field-wrap {
		padding-top: 6px;
	}
}
.inline-input-group-date {
	.first-input {
		width: 62.5%;

		> label {
			width: 49.4% !important;
		}

		.field-wrap {
			width: 75.2%;
			> .datepicker-wrapper {
				width: 100%;
			}
		}
	}
	.second-input {
		padding-left: 10px;
		width: 37%;

		> label {
			width: auto;
		}
		.field-wrap {
			width: 25%;
		}
	}
}

.inline-input-price {
	.first-input {
		width: 45.5%;

		> label {
			width: 55.4% !important;
		}

		.field-wrap {
			width: 45.2%;
		}
	}
	.second-input {
		padding-left: 10px;
	}
}

.product-modal .modal-body {
	.vue-form-generator {
		.field-radios-group {
			.field-wrap {
				padding-top: 6px;
			}
		}
		.form-group {
			label {
				width: 25%;
			}

			div[role='radiogroup'] {
				label {
					width: 100% !important;
				}
			}
		}
		.inline-input-group-date {
			.form-group {
				label {
					width: auto;
				}
			}
		}

		.datepicker-wrapper {
			width: 75%;
		}
		.productSettedUp {
			.field-wrap {
				color: var(--danger);
				font-weight: bold;
			}
		}
	}
}
</style>
